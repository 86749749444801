import { lazy } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useGetDataQuery } from "../redux/Apis/General"
import PrivateRoute from "./PrivateRoute"

const Home = lazy(() => import('../screens/home'))
const Login = lazy(() => import('../screens/auth/login'))
const Signup = lazy(() => import('../screens/auth/signup'))
const ForgotPassword = lazy(() => import('../screens/auth/forgotpassword'))
const SetPassword = lazy(() => import('../screens/auth/setpassword'))
const Create = lazy(() => import('../screens/course/create'))
const Detail = lazy(() => import('../screens/course/detail'))
const MyLearning = lazy(() => import('../screens/mylearning'))
const Cart = lazy(() => import('../screens/cart'))
const Checkout = lazy(() => import('../screens/checkout'))
const Membership = lazy(() => import('../screens/membership'))
const Dashboard = lazy(() => import('../screens/dashboard'))
const Classes = lazy(() => import('../screens/classes'))
const ClassDetail = lazy(() => import('../screens/classdetail'))
const Lecture = lazy(() => import('../screens/lecture'))
const Terms = lazy(() => import('../screens/content/terms'))
const Privacy = lazy(() => import('../screens/content/privacy'))
const Cookies = lazy(() => import('../screens/content/cookies'))
const Refund = lazy(() => import('../screens/content/refund'))
const PhysicalActivityDisclaimer = lazy(() => import('../screens/content/physicalactivitydisclaimer'))
const Dei = lazy(() => import('../screens/content/dei'))
const SuccessStory = lazy(() => import('../screens/successstory'))
const Business = lazy(() => import('../screens/business'))
const EditProfile = lazy(() => import('../screens/profile/editprofile'))
const InstructorDetails = lazy(() => import('../screens/instructordetails'))
const Journey = lazy(() => import('../screens/journey'))
const Contact = lazy(() => import('../screens/contact'))
const Authlayout = lazy(() => import('../components/layouts/authlayout'))

const Router = () => {

    const { } = useGetDataQuery()

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="auth/" exact element={<Authlayout />}>
                    <Route path="login" exact element={<Login />} />
                    <Route path="signup" exact element={<Signup />} />
                    <Route path="forgot-password" exact element={<ForgotPassword />} />
                    <Route path="set-password" exact element={<SetPassword />} />
                </Route>
                <Route path="/my-learning" element={<MyLearning />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
                <Route path="/membership" element={<Membership />} />
                <Route path="/instructor-dashboard" element={<Dashboard />} />
                <Route path="/course/create" exact element={<Create />} />
                <Route path="/course/details" exact element={<Detail />} />
                <Route path="/classes" element={<Classes />} />
                <Route path="/class-detail/:id" element={<ClassDetail />} />
                <Route path="/lecture/:id" element={<PrivateRoute><Lecture /></PrivateRoute>} />
                <Route path="/success-story" element={<SuccessStory />} />
                <Route path="/our-journey" element={<Journey />} />
                <Route path="/terms-and-condition" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/physical-activity-disclaimer" element={<PhysicalActivityDisclaimer />} />
                <Route path="/dei" element={<Dei />} />
                <Route path="/cookies-policy" element={<Cookies />} />
                <Route path="/refund-policy" element={<Refund />} />
                <Route path="/business" element={<Business />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/profile/edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                <Route path="/instructor/:id" element={<InstructorDetails />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router